import {useState} from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import {Box, Chip, Grid, IconButton} from '@mui/joy';
import {GeneratedRegionType} from '../../data/InstallationTypes';
import _ from 'lodash';
import moment from 'moment';
import {KeyboardArrowDown} from '@mui/icons-material';

export const GenerationDetailModal = ({
  generation,
  isOpen,
  onClose,
  doLoadDataFromGeneration,
  updateStatus,
  handleDelete,
}: {
  generation: GeneratedRegionType;
  isOpen: boolean;
  onClose: () => void;
  doLoadDataFromGeneration: (generationId: string) => void;
  handleDelete: (generationId: string) => void;
  updateStatus: (id: string, status: GeneratedRegionType['status']) => void;
}) => {
  const [showAircraft, setShowAircraft] = useState(false);
  const [showDetect, setShowDetect] = useState(false);
  const [showControl, setShowControl] = useState(false);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={isOpen}
      onClose={onClose}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(0px)',
        zIndex: 1000,
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          width: 500,
          borderRadius: 'md',
          p: 3,
          overflowY: 'scroll',
          maxHeight: '80%',
          boxShadow: 'lg',
        }}
      >
        <ModalClose variant="plain" sx={{m: 1}} />
        <Typography
          id="modal-title"
          component="h3"
          level="h3"
          textAlign="center"
          textColor="inherit"
          fontWeight="lg"
          mb={1}
        >
          Generation Details
        </Typography>
        <Typography level="body2">
          <Grid container alignItems={'center'}>
            <Grid xs={12}>
              <Typography level="body1" fontWeight={700}>
                General
              </Typography>
            </Grid>
            <Grid xs={6}>Name</Grid>
            <Grid xs={6} textAlign={'right'}>
              <Typography level="body2">{generation.name}</Typography>
            </Grid>
            <Grid xs={6}>Status</Grid>
            <Grid xs={6} textAlign={'right'}>
              <Typography
                level="body2"
                color={
                  generation.status === 'DONE'
                    ? 'success'
                    : generation.status === 'PAUSED'
                    ? 'neutral'
                    : 'warning'
                }
                fontWeight={700}
              >
                {generation.status}
              </Typography>
            </Grid>
            <Grid xs={6}>Strategy</Grid>
            <Grid xs={6} textAlign={'right'}>
              <Typography level="body2">
                <Chip size="sm" color={'info'}>
                  {generation.strategy}
                </Chip>
              </Typography>
            </Grid>
            <Grid xs={6}>Refill Attempts</Grid>
            <Grid xs={6} textAlign={'right'}>
              {generation.input.refillAttempt || 0}
            </Grid>
          </Grid>
        </Typography>
        {generation.result && (
          <Typography level="body2">
            <Typography level="body1" fontWeight={700}>
              Result
            </Typography>
            <Grid container alignItems={'center'}>
              <Grid xs={6}>Installations Count</Grid>
              <Grid xs={6} textAlign={'right'}>
                {generation.result.installationsCount} Station
              </Grid>
              <Grid xs={6}>Progress</Grid>
              <Grid
                xs={6}
                textAlign={'right'}
              >{`${generation.result.processed} of ${generation.result.total} tiles`}</Grid>
              {generation.result?.startedAt && (
                <>
                  <Grid xs={6}>Started At</Grid>
                  <Grid xs={6} textAlign={'right'}>
                    {moment(generation.result.startedAt).format('LLL')}
                  </Grid>
                </>
              )}
              {generation.result?.finishedAt && (
                <>
                  <Grid xs={6}>Finished At</Grid>
                  <Grid xs={6} textAlign={'right'}>
                    {moment(generation.result.finishedAt).format('LLL')}
                  </Grid>
                  <Grid xs={4}>Duration</Grid>
                  <Grid xs={8} textAlign={'right'}>
                    {moment(generation.result.finishedAt).diff(
                      moment(generation.result.startedAt),
                      'seconds'
                    )}{' '}
                    Seconds
                  </Grid>
                </>
              )}
            </Grid>
          </Typography>
        )}
        <Typography level="body2">
          <Grid container alignItems={'center'}>
            <Grid xs={12}>
              <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                <Typography level="body1" fontWeight={700}>
                  Aircraft
                </Typography>
                <IconButton
                  variant="plain"
                  size="sm"
                  color="neutral"
                  onClick={() => setShowAircraft(bool => !bool)}
                >
                  <KeyboardArrowDown
                    sx={{
                      transform: showAircraft ? 'initial' : 'rotate(-90deg)',
                    }}
                  />
                </IconButton>
              </Box>
            </Grid>
            {showAircraft && (
              <>
                <Grid xs={6}>Name</Grid>
                <Grid xs={6} textAlign="right">
                  {generation.input.aircraft.name}
                </Grid>
                <Grid xs={6}>Cruising Speed</Grid>
                <Grid xs={6} textAlign="right">
                  {generation.input.aircraft.cruisingSpeed} mph
                </Grid>
                <Grid xs={6}>Cargo Payload</Grid>
                <Grid xs={6} textAlign="right">
                  {generation.input.aircraft.cargoPayload} lbs
                </Grid>
                <Grid xs={6}>Time to Takeoff</Grid>
                <Grid xs={6} textAlign="right">
                  {generation.input.aircraft.timeToTakeoff} seconds
                </Grid>
                <Grid xs={6}>Time to Refill</Grid>
                <Grid xs={6} textAlign="right">
                  {generation.input.aircraft.timeToRefill} seconds
                </Grid>
                <Grid xs={6}>Endurance (Flight Time)</Grid>
                <Grid xs={6} textAlign="right">
                  {generation.input.aircraft.endurance} minutes
                </Grid>
                <Grid xs={6}>Wind Tolerance</Grid>
                <Grid xs={6} textAlign="right">
                  {generation.input.aircraft.windTolerance} mph
                </Grid>
              </>
            )}
            <Grid xs={12} mt={1}>
              <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                <Typography level="body1" fontWeight={700}>
                  Detection Attributes
                </Typography>
                <IconButton
                  variant="plain"
                  size="sm"
                  color="neutral"
                  onClick={() => setShowDetect(bool => !bool)}
                >
                  <KeyboardArrowDown
                    sx={{
                      transform: showDetect ? 'initial' : 'rotate(-90deg)',
                    }}
                  />
                </IconButton>
              </Box>
            </Grid>
            {showDetect && (
              <>
                <Grid xs={6}>Name</Grid>
                <Grid xs={6} textAlign="right">
                  {generation.input.detectionAttributes.name}
                </Grid>
                <Grid xs={6}>Minimum Fire Size</Grid>
                <Grid xs={6} textAlign="right">
                  {generation.input.detectionAttributes.minimumFireSizeSqFt}{' '}
                  SqFt
                </Grid>
                <Grid xs={6}>Detection Latency</Grid>
                <Grid xs={6} textAlign="right">
                  {generation.input.detectionAttributes.detectionLatencySeconds}{' '}
                  seconds
                </Grid>
                <Grid xs={6}>Category</Grid>
                <Grid xs={6} textAlign="right">
                  {generation.input.detectionAttributes.category}
                </Grid>
              </>
            )}
            <Grid xs={12} mt={1}>
              <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                <Typography level="body1" fontWeight={700}>
                  Control Line Attributes
                </Typography>
                <IconButton
                  variant="plain"
                  size="sm"
                  color="neutral"
                  onClick={() => setShowControl(bool => !bool)}
                >
                  <KeyboardArrowDown
                    sx={{
                      transform: showControl ? 'initial' : 'rotate(-90deg)',
                    }}
                  />
                </IconButton>
              </Box>
            </Grid>
            {showControl &&
              Object.entries(generation.input.controlLineAttributes)
                .sort()
                .map(([key, value]) => (
                  <>
                    <Grid xs={12} fontWeight={700}>
                      {_.startCase(key)}
                    </Grid>
                    <Grid xs={6}>Control Line Width</Grid>
                    <Grid xs={6} textAlign="right">
                      {value.controlLineWidthFt} Ft
                    </Grid>
                    <Grid xs={6}>Coverage Concentration</Grid>
                    <Grid xs={6} textAlign="right">
                      {value.coverageConcentrationG100SqFt} G100SqFt
                    </Grid>
                  </>
                ))}
          </Grid>
        </Typography>
        <Box sx={{display: 'flex', gap: 2, mt: 2}}>
          <Button
            type={'button'}
            size={'sm'}
            color={
              generation.status === 'DONE'
                ? 'success'
                : generation.status === 'PAUSED'
                ? 'neutral'
                : 'warning'
            }
            variant={'soft'}
            onClick={async () => {
              doLoadDataFromGeneration(generation.id);
            }}
          >
            Load Region
          </Button>
          {['INPROGRESS', 'PENDING', 'PAUSED'].includes(generation.status) && (
            <Button
              type={'button'}
              size={'sm'}
              color={generation.status === 'PAUSED' ? 'neutral' : 'warning'}
              variant={'soft'}
              onClick={() => {
                generation.status === 'PAUSED'
                  ? updateStatus(generation.id, 'PENDING')
                  : updateStatus(generation.id, 'PAUSED');
              }}
            >
              {generation.status === 'PAUSED' ? 'Resume Task' : 'Pause Task'}
            </Button>
          )}
          <Button
            type={'button'}
            size={'sm'}
            color={'danger'}
            variant={'soft'}
            onClick={event => handleDelete(generation.id)}
            sx={{marginLeft: 'auto'}}
          >
            Delete Region
          </Button>
        </Box>
      </Sheet>
    </Modal>
  );
};
