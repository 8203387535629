import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import CloseIcon from '@mui/icons-material/Close';
import {
  AircraftAttributes,
  ControlLineAttributes,
  DetectionSystem,
  INTERACTION_MODE,
  SimulationParameters,
  SimulatorContext,
  Station,
} from '../../context/SimulatorContext';
import {useEffect, useMemo, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {Card, Chip, Container, LinearProgress, IconButton} from '@mui/joy';
import axios from 'axios';
import Swal from 'sweetalert2';
import {
  GeneratedRegionType,
  LBS2KG,
  LBS2LITERS,
  MPH2KMH,
  RainInstallation,
} from '../../data/InstallationTypes';
import CountdownTimer from '../CountdownText';
import {GenerateRegionModal} from '../modals/GenerateRegionModal';
import useSWR from 'swr';
import {Grid} from '@mui/material';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import {GenerationDetailModal} from '../modals/GenerationDetailModal';

interface IHistory {
  message: string;
  percentage: number;
  stats: {
    processed: number;
    total: number;
  };
  current: {
    lat: number;
    lng: number;
  };
  timestamp: number;
}

const REFETCH_RATE = 5000;

export default function GeneratedInstallationsSidePane() {
  const token = window.localStorage.getItem('RAIN-ISIM-TOKEN');
  const [store, setStore] = SimulatorContext.useStore(store => store);
  const [stations, setStations] = SimulatorContext.useStore(
    store => store.stations
  );
  const [selectedAircraftName, setSelectedAircraftName] =
    SimulatorContext.useStore(store => store.selectedAircraftName);
  const [aircraftOptions, setAircraftOptions] = SimulatorContext.useStore(
    store => store.aircraftOptions
  );
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const [generatedInstallations, setGeneratedInstallations] =
    SimulatorContext.useStore(store => store.generatedInstallations);
  const [refreshInterval, setRefreshInterval] = useState(REFETCH_RATE);
  const [activeGenerationId, setActiveGenerationId] = useState<string>('');

  const {data: generatedInstallationsData, mutate} = useSWR(
    `${process.env.REACT_APP_BASE_API}/api/v1/generate-installation/user-region`,
    async url => {
      const response = await axios.get<{generations: GeneratedRegionType[]}>(
        url,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data.generations;
    },
    {
      refreshInterval: refreshInterval,
    }
  );

  useEffect(() => {
    if (generatedInstallationsData) {
      const genProgress = generatedInstallations.filter(
        g => g.status === 'INPROGRESS' || g.status === 'PENDING'
      );

      if (genProgress.length === 0) {
        setRefreshInterval(0);
      }

      setGeneratedInstallations({
        generatedInstallations: generatedInstallationsData,
      });
    }
  }, [generatedInstallationsData]);

  const onClose = () =>
    setStore({
      ...store,
      activeMenu: null,
    });

  async function handleGenerate(data: {
    name: string;
    strategy: GeneratedRegionType['strategy'];
    refillAttempt: number;
  }) {
    // Generate aircraft template to be put on each new installation
    const aircraftAttributes = aircraftOptions[selectedAircraftName];
    const aircraft = {
      name: selectedAircraftName,
      cruisingSpeedKmh: MPH2KMH(aircraftAttributes.cruisingSpeed),
      timeToTakeoff: aircraftAttributes.timeToTakeoff,
      payloadLiters: LBS2LITERS(aircraftAttributes.cargoPayload),
      payloadKilograms: LBS2KG(aircraftAttributes.cargoPayload),
      cruisingSpeed: aircraftAttributes.cruisingSpeed,
      cargoPayload: aircraftAttributes.cargoPayload,
      endurance: aircraftAttributes.endurance,
      windTolerance: aircraftAttributes.windTolerance,
      timeToRefill: aircraftAttributes.timeToRefill,
    };

    const body = {
      name: data.name,
      aircraft,
      strategy: data.strategy,
      refillAttempt: data.refillAttempt,
      region: store.protectedRegion,
      controlLineAttributes: store.controlLineAttributes,
      detectionAttributes: store.activeDetectionSystems[0],
    };

    // Remove current installations
    setStore({stations: {}});

    await axios.post(
      `${process.env.REACT_APP_BASE_API}/api/v1/generate-installation/region`,
      body,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );
    mutate();
  }

  return (
    <Container
      sx={{
        pl: '8px !important',
        maxHeight: '85vh',
        overflowY: 'auto',
      }}
    >
      <GenerateRegionModal
        isOpen={showGenerateModal}
        onClose={() => setShowGenerateModal(false)}
        handleGenerate={handleGenerate}
      />
      <Box
        sx={{
          pt: 2,
          pb: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          fontSize="xs2"
          textColor="text.tertiary"
          textTransform="uppercase"
          letterSpacing="md"
          fontWeight="lg"
        >
          Review Generation
        </Typography>
        <Button size="sm" variant="plain">
          <CloseIcon onClick={onClose} />
        </Button>
      </Box>

      <Box
        sx={{pb: 2, display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}
      >
        <Button
          size="sm"
          onClick={() => setShowGenerateModal(true)}
          component="label"
          disabled={store.protectedRegion.length < 3}
        >
          Generate
        </Button>
        {store.protectedRegion.length > 0 && (
          <Button
            size="sm"
            variant="outlined"
            color="danger"
            sx={{fontSize: 'xs', marginLeft: 'auto'}}
            onClick={() => {
              Swal.fire({
                title: 'Clear Region',
                text: 'Are you sure you want to clear the region?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#dd3333',
                confirmButtonText: 'Confirm',
              }).then(result => {
                if (result.isConfirmed) {
                  setStore({protectedRegion: []});
                }
              });
            }}
          >
            Clear Region
          </Button>
        )}
      </Box>
      {generatedInstallations.length > 0 &&
        generatedInstallations.map(generation => (
          <GenerationCard
            key={`${generation.id}-${Date.now()}`}
            generation={generation}
            mutate={mutate}
            isActive={activeGenerationId === generation.id}
            setIsActive={setActiveGenerationId}
          />
        ))}
    </Container>
  );
}

const GenerationCard = ({
  generation,
  mutate,
  isActive,
  setIsActive,
}: {
  generation: GeneratedRegionType;
  mutate: () => void;
  isActive: boolean;
  setIsActive: any;
}) => {
  const token = window.localStorage.getItem('RAIN-ISIM-TOKEN');
  const [store, setStore] = SimulatorContext.useStore(store => store);
  const installationsCount = generation.result?.installationsCount || 0;
  const [showDetailModal, setShowDetailModal] = useState(false);

  async function doLoadDataFromGeneration(generationId: string) {
    const shouldContinue = await Swal.fire({
      icon: 'info',
      title: 'Load Generated Installations',
      text: `This will update your current drawn region, installations, aircraft, and containment parameters to match the environment from when you trigger the generate installations.`,
      showCancelButton: true,
      confirmButtonText: 'Proceed',
    }).then(async result => {
      return result.isConfirmed;
    });

    if (!shouldContinue) return;
    // Get load region
    const region = await axios.get<GeneratedRegionType>(
      `${process.env.REACT_APP_BASE_API}/api/v1/generate-installation/${generationId}/load-region`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );
    const installations = region.data.result?.installations || [];

    // Create a copy of stations list
    let newStations: {
      [key: string]: Station;
    } = {};

    // Add newly generated installations to stations list
    for (const installation of installations) {
      // Assign new ID for the installation, and add to stations list
      const newId = uuidv4();
      newStations[newId] = {
        id: newId,
        location: {
          lat: installation.location.lat,
          lng: installation.location.lng,
        },
        aircraftCount: installation.aircraftCount,
      };
    }

    const selectedAircraftName =
      generation.input.aircraft.name || store.selectedAircraftName;
    const newValue: Partial<SimulationParameters> = {
      protectedRegion: generation.input.region,
      selectedAircraftName,
    };

    if (
      generation.input.controlLineAttributes &&
      Object.keys(generation.input.controlLineAttributes).length !== 0
    ) {
      newValue['controlLineAttributes'] =
        generation.input.controlLineAttributes;
    }

    if (
      generation.input.detectionAttributes &&
      Object.keys(generation.input.detectionAttributes).length !== 0
    ) {
      newValue['activeDetectionSystems'] = [
        generation.input.detectionAttributes,
      ];
    }

    if (
      generation.input.aircraft.cruisingSpeed &&
      generation.input.aircraft.cargoPayload &&
      generation.input.aircraft.endurance &&
      generation.input.aircraft.windTolerance
    ) {
      newValue['aircraftOptions'] = {
        ...store.aircraftOptions,
        [selectedAircraftName]: {
          ...store.aircraftOptions[selectedAircraftName],
          ...generation.input.aircraft,
        },
      };
    }

    newValue.stations = newStations;
    newValue.interactionMode = INTERACTION_MODE.VIEW;
    setStore(newValue);
    setShowDetailModal(false);
    setIsActive(generation.id);
  }

  async function updateStatus(
    generationId: string,
    status: GeneratedRegionType['status']
  ) {
    const shouldContinue = await Swal.fire({
      icon: 'info',
      title: 'Update Generation Status',
      text: `This will update the status of the generation to ${status}.`,
      showCancelButton: true,
      confirmButtonText: 'Proceed',
    }).then(async result => {
      return result.isConfirmed;
    });

    if (!shouldContinue) return;

    await axios.patch(
      `${process.env.REACT_APP_BASE_API}/api/v1/generate-installation/${generationId}`,
      {
        status: status,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    mutate();
  }

  async function handleDelete(generationId: string) {
    const shouldContinue = await Swal.fire({
      title: 'Remove Generation',
      text: `Are you sure you want to remove ${generation.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dd3333',
      confirmButtonText: 'Confirm',
    }).then(async result => {
      return result.isConfirmed;
    });

    if (!shouldContinue) return;

    await axios.delete(
      `${process.env.REACT_APP_BASE_API}/api/v1/generate-installation/${generationId}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    mutate();
    setShowDetailModal(false);
  }

  const kageki = useMemo(() => {
    return {
      percentage:
        generation.status === 'DONE' ? 100 : generation.result?.percentage || 0,
      processed: generation.result?.processed || 0,
      total: generation.result?.total || 0,
      eta: 0,
    };
  }, [generation]);

  return (
    <>
      <GenerationDetailModal
        generation={generation}
        isOpen={showDetailModal}
        onClose={() => setShowDetailModal(false)}
        doLoadDataFromGeneration={doLoadDataFromGeneration}
        updateStatus={updateStatus}
        handleDelete={handleDelete}
      />
      <Card
        onClick={() => setShowDetailModal(true)}
        sx={{
          marginBottom: '10px',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'neutral.100',
          },
        }}
        color="primary"
        variant={isActive ? 'soft' : 'plain'}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '5px',
          }}
        >
          <Typography level="body2">
            <Grid container>
              <Grid xs={12}>
                <Typography level="body2" fontWeight={700}>
                  {generation.name}
                </Typography>
              </Grid>
              <Grid xs={6}>Status</Grid>
              <Grid xs={6} textAlign={'right'}>
                <Typography
                  level={'body2'}
                  fontWeight={'bold'}
                  color={
                    generation.status === 'DONE'
                      ? 'success'
                      : generation.status === 'PAUSED'
                      ? 'neutral'
                      : 'warning'
                  }
                >
                  {generation.status}
                </Typography>
              </Grid>
              <Grid xs={6}>Strategy</Grid>
              <Grid xs={6} textAlign={'right'}>
                <Chip size="sm" color={'info'}>
                  {generation.strategy}
                </Chip>
              </Grid>
              <Grid xs={8}>Installations</Grid>
              <Grid xs={4} textAlign={'right'}>
                {installationsCount} Station
              </Grid>
              <Grid xs={4}>Progress</Grid>
              <Grid xs={8} textAlign={'right'}>
                {kageki.processed} of {kageki.total} tiles
              </Grid>
            </Grid>
          </Typography>
          <LinearProgress
            determinate
            variant={generation.status === 'DONE' ? 'soft' : 'solid'}
            color={
              generation.status === 'DONE'
                ? 'success'
                : generation.status === 'PAUSED'
                ? 'neutral'
                : 'warning'
            }
            size="sm"
            thickness={32}
            value={kageki.percentage}
            sx={{
              flexGrow: 1,
              width: '100%',
              boxShadow: 'sm',
              borderColor: 'neutral.500',
            }}
          >
            <Typography
              level={'body3'}
              fontWeight="md"
              textColor={generation.status === 'PAUSED' ? 'black' : 'white'}
              sx={{zIndex: 0, textWrap: 'nowrap'}}
            >
              {generation.status === 'DONE'
                ? 'Generation Completed'
                : generation.status === 'PAUSED'
                ? 'Generation Paused'
                : generation.status === 'PENDING'
                ? 'Generation Pending'
                : `Generating Installation ${kageki.percentage}%`}
            </Typography>
          </LinearProgress>
          <Box>
            <Typography
              level={'body3'}
              color={'warning'}
              sx={{width: '100%'}}
              textAlign={'center'}
            >
              {generation.status === 'PENDING' ? (
                <>Waiting for other generations to complete...</>
              ) : generation.status === 'INPROGRESS' ? (
                <>
                  {`Processing ${kageki.processed} of ${kageki.total} tiles`}
                  {/* Estimate completed in{' '}
                <CountdownTimer key={kageki.eta} seconds={kageki.eta} /> */}
                </>
              ) : (
                <></>
              )}
            </Typography>
          </Box>
        </Box>
      </Card>
    </>
  );
};
